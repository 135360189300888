import { f7 } from 'framework7-svelte';
import mealPlanStore, { initPlanForUser, fetchUserPlan, getRecipeDetailsFromId, selectedRecipe } from "../stores/mealPlan"
import { initBucket, loginUser, supabase, initUser } from '../js/supabase'
import { getContests } from "../stores/contests";

const dayMap = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    0 : "Sunday",
    1 : "Monday",
    2 : "Tuesday",
    3 : "Wednesday",
    4 : "Thursday",
    5 : "Friday",
    6 : "Saturday"
};

export function showToast(message, buttonText, buttonCallback){
    let toastOptions = {
        text: message,
        destroyOnClose: true,
        cssClass : 'custom-toast',
        closeTimeout: 2000
    }

    if(buttonText && buttonCallback){
        toastOptions.closeButton = true;
        toastOptions.closeButtonText = buttonText;
        toastOptions.on = {
            closeButtonClick : buttonCallback
        }
    }

    let toastBottom = f7.toast.create(toastOptions);

    // Open it
    toastBottom.open();
}

export function startOfWeek(date, startDay) {
    let startDayNumber = typeof startDay === 'string' ? dayMap[startDay] : startDay;
    let currentDay = date.getDay();
    let diff = date.getDate() - currentDay + ((currentDay < startDayNumber) ? (startDayNumber - 7) : startDayNumber);
    return new Date(date.setDate(diff));
}

export function getPreviousWeek(writableCurrentStartDate){
    let previousWeekDate;
    writableCurrentStartDate.subscribe(value=>{
        previousWeekDate = value;
    })();

    previousWeekDate.setDate(previousWeekDate.getDate() - 7);

    writableCurrentStartDate.set(previousWeekDate);

    const endOfWeek = new Date(previousWeekDate.toISOString());
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return {
        startDate: previousWeekDate,
        endDate: endOfWeek
    }
}

export function getNextWeek(writableCurrentStartDate){
    let thisDate;
    writableCurrentStartDate.subscribe(value=>{
        thisDate = value;
    })();

    thisDate.setDate(thisDate.getDate() + 7);

    writableCurrentStartDate.set(thisDate);

    let endOfWeek = new Date(thisDate.toISOString());
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return {
        startDate: thisDate,
        endDate: endOfWeek
    }
}

export function convertDayToDateInWeek(day, weekStart) {
    let resultDate = new Date(weekStart.toISOString());
    let daysToAdd = 0;
    switch(day) {
        case 'Tuesday':
            daysToAdd = 1;
            break;
        case 'Wednesday':
            daysToAdd = 2;
            break;
        case 'Thursday':
            daysToAdd = 3;
            break;
        case 'Friday':
            daysToAdd = 4;
            break;
        case 'Saturday':
            daysToAdd = 5;
            break;
        case 'Sunday':
            daysToAdd = 6;
            break;
    }
    resultDate.setDate(resultDate.getDate() + daysToAdd);

    return resultDate;
}

export function calculateLines(textarea) {
    // Get the computed style of the textarea
    var style = window.getComputedStyle(textarea);

    // Get the line-height value, remove 'px', and convert to a number
    var lineHeight = parseFloat(style.lineHeight);

    // Calculate the number of lines
    var lines = textarea.scrollHeight / lineHeight;

    return Math.round(lines);
}

export function autoExpand(element) {
    if(calculateLines(element.target) > 1){
        element.target.style.height = 'auto';
        element.target.style.height = element.target.scrollHeight + 'px';
    } else {
        element.target.style.height = "1.2em";
    }
}

export function removeTimezone(dayTime) {
    const MINUTES_TO_HOUR = 60;
    const newDate = new Date(dayTime);
    newDate.setHours(newDate.getHours() - (newDate.getTimezoneOffset() / MINUTES_TO_HOUR ));
    return newDate;
}

export function offsetTimezone(dayTime, isPositive = false) {
    const newDate = new Date(dayTime);
    isPositive ?
        newDate.setHours(0, newDate.getTimezoneOffset(), 0, 0)
    :
        newDate.setHours(0, -newDate.getTimezoneOffset(), 0, 0);
    return newDate;
}

export function getBeginningOfWeek(dateObj) {
    const d = new Date(dateObj.getTime());
    const day = d.getUTCDay();
    d.setUTCDate(d.getUTCDate() - (day === 0 ? 6 : day - 1)); // adjust for Sunday being 0
    return d.toISOString().split('T')[0]; // return in 'YYYY-MM-DD' format
}

export function getEndOfWeek(dateObj) {
    const d = new Date(dateObj.getTime());
    const day = d.getUTCDay();
    d.setUTCDate(d.getUTCDate() + (day === 0 ? 0 : 7 - day)); // adjust for Sunday being 0
    return d.toISOString().split('T')[0]; // return in 'YYYY-MM-DD' format
}

export var appProgressBar = {
    // Show the progress bar
    show: function() {
        var bar = document.getElementById('main-progress-bar');
        if (bar) {
            bar.style.display = 'block'; // Change 'block' to 'inline' or 'inline-block' if needed
        }
    },

    // Hide the progress bar
    hide: function() {
        var bar = document.getElementById('main-progress-bar');
        if (bar) {
            bar.style.display = 'none';
        }
    }
};

export async function handleDeepLink(url){
    // Check for the hash URLs
    const hash = new URL(url ? url : window.location.href).hash.substring(1) // Remove the '#' character
    const hashParams = new URLSearchParams(hash);
    const accessToken = hashParams.get('access_token');
    const refreshToken = hashParams.get('refresh_token');
    const expiresIn = hashParams.get('expires_in');
    const tokenType = hashParams.get('token_type');

    // Check for the search perams
    const params = new URLSearchParams(new URL(url ? url : window.location.href).search);
    const method = params.get('method');

    if(!method && !accessToken){
      showToast('Unable to open link.')
      return
    }
    
    if(method == "view_recipe"){
      const recipeId = params.get('recipe_id');
      const external = params.get('external');

      if(!recipeId){
        showToast('Unable to open recipe. Invalid recipe_id')
      }

      f7.views.current.router.navigate(`/recipe/`, {
          props: {
              addToMealPlan: true
          },
      });

      let recipeData = await getRecipeDetailsFromId({
        recipeId,
        isExternal : external,
      });

      selectedRecipe.set(recipeData);
    } else if(method == "email_verified"){
      if(email && password){
        // Attempt login with current entered credentials
        await loginUser({
          email : email,
          password : password
        });
      } else {
        f7.dialog.alert('Your email has been verified! You can now login using your credentials.')
      }
    } else if(method == "open_contest") {
        const contestId = params.get('contest_id');

        await getContests();

        f7.views.current.router.navigate(`/contest/${contestId}/`);
    } else if(method) {
      showToast(`Method ${method} is not supported in this version of Zestyplan`)
      return
    }

    if(accessToken && refreshToken){
      const { data, error } = await supabase.auth.setSession({
        access_token : accessToken,
        refresh_token : refreshToken,
        expires_in : expiresIn,
        token_type : tokenType
      });

      if(!error){
        f7.loginScreen.close()
        await initUser();
        const { data: { user } } = await supabase.auth.getUser();

        if (user) {
          await initPlanForUser();
          await initBucket();
          await fetchUserPlan(new Date());
        }
      } else {
        throw new Error(error)
      }
    }
}